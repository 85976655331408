var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.projectDetail)?_c('div',{staticClass:"basic-info-page pb-8 bg-purple-bg"},[_c('button',{staticClass:"text-sm mb-8 cursor-pointer",on:{"click":function () { return _vm.$router.push('/manage/projects'); }}},[_vm._v(" << "+_vm._s(_vm.$t('BACK_BTN'))+" ")]),_c('section',{staticClass:"text-3xl mb-12 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_BASIC_INFO_PAGE_TITLE'))+" ")]),_c('section',{staticClass:"project-info"},[_c('ProjectViewSection',{attrs:{"title":"PROJECT_GENERAL_SECTION_TITLE","dataSource":_vm.projectDetail,"columns":_vm.generalSection},scopedSlots:_vm._u([{key:"image_uploaded_name",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{},[_c('a',{staticClass:"font-semibold",attrs:{"target":"_blank","download":"","href":record.nft_images_url}},[_vm._v(_vm._s(value))])])}},{key:"json_uploaded_name",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{},[_c('a',{staticClass:"font-semibold",attrs:{"target":"_blank","download":"","href":record.nft_json_url}},[_vm._v(_vm._s(value))])])}},{key:"reveal",fn:function(ref){
var record = ref.record;
return _c('div',{staticClass:"flex items-center"},[_c('div',[_vm._v(" "+_vm._s(record.can_reveal ? 'Yes' : 'No')+" ")]),(record.can_reveal)?_c('div',{staticClass:"ml-4 md:ml-6"},[_c('a',{staticClass:"font-semibold",attrs:{"target":"_blank","download":"","href":record.reveal_thumbnail_url}},[_vm._v(" "+_vm._s(record.reveal_uploaded_name)+" ")])]):_vm._e()])}},{key:"chain_id",fn:function(record){return _c('div',{},[_c('div',{attrs:{"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.CHAIN_INFO[record.value].name)+" ")])])}},{key:"payment",fn:function(record){return _c('div',{},[_c('div',[_vm._v(" "+_vm._s(record.value === 1 ? _vm.$t('有効') : _vm.$t('無効'))+" ")])])}},{key:"website_url",fn:function(record){return _c('div',{},[_c('a',{staticClass:"text-primary font-semibold",attrs:{"target":"_blank","href":record.value}},[_vm._v(_vm._s(record.value))])])}},{key:"twitter_url",fn:function(record){return _c('div',{},[_c('a',{staticClass:"text-primary font-semibold",attrs:{"target":"_blank","href":record.value}},[_vm._v(_vm._s(record.value))])])}},{key:"address",fn:function(ref){
var value = ref.value;
return _c('div',{},[_c('div',{staticClass:"text-18px font-semibold"},[_vm._v(" "+_vm._s(value)+" ")])])}}],null,false,3105478506)},[_c('div',{attrs:{"slot":"name"},slot:"name"},[_c('span',[_vm._v(" "+_vm._s(_vm.showName)+" ")])]),_c('div',{attrs:{"slot":"detail"},slot:"detail"},[_c('div',{staticClass:"break-words whitespace-pre-line"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.showDescription))}})])])]),_c('ProjectViewSection',{attrs:{"dataSource":_vm.projectDetail,"title":"PROJECT_PRESALE_SECTION_TITLE","columns":_vm.presaleSection},scopedSlots:_vm._u([{key:"start",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{staticClass:"text-xl font-semibold"},[(value)?[_vm._v(" "+_vm._s(_vm._f("toJST")(value)))]:(record.is_presale_tba)?[_vm._v(_vm._s(_vm.$t('TBA')))]:_vm._e()],2)}},{key:"end",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{staticClass:"text-xl font-semibold"},[(value)?[_vm._v(" "+_vm._s(_vm._f("toJST")(value)))]:(record.is_presale_tba)?[_vm._v(_vm._s(_vm.$t('TBA')))]:_vm._e()],2)}},{key:"amount",fn:function(record){return _c('div',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(record.value))+" ")])}},{key:"price",fn:function(record){return _c('div',{staticClass:"font-semibold"},[(_vm.projectDetail.is_presale_price_tba)?_c('div',[_vm._v(_vm._s(_vm.$t('TBA')))]):_c('div',[_vm._v(_vm._s(_vm._f("toCurrency")(record.value))+" "+_vm._s(_vm.symbol))])])}},{key:"whitelist",fn:function(record){return _c('div',{},[_c('a',{staticClass:"font-semibold",attrs:{"target":"_blank","download":"","href":record.value}},[_vm._v(_vm._s(record.value))])])}},{key:"max_purchase",fn:function(record){return _c('div',{},[_c('a',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm._f("toCurrency")(record.value)))])])}},{key:"transfer_unsold_disabled",fn:function(record){return _c('div',{},[_c('a',{staticClass:"font-semibold"},[_vm._v(_vm._s(record.value ? 'No' : 'Yes'))])])}}],null,false,2171550271)}),_c('ProjectViewSection',{attrs:{"dataSource":_vm.projectDetail,"title":"PROJECT_PUBSALE_SECTION_TITLE","columns":_vm.publicSaleSection},scopedSlots:_vm._u([{key:"start",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{staticClass:"text-xl font-semibold"},[(value)?[_vm._v(" "+_vm._s(_vm._f("toJST")(value)))]:(record.is_publicsale_tba)?[_vm._v(_vm._s(_vm.$t('TBA')))]:_vm._e()],2)}},{key:"end",fn:function(ref){
var value = ref.value;
var record = ref.record;
return _c('div',{staticClass:"text-xl font-semibold"},[(value)?[_vm._v(" "+_vm._s(_vm._f("toJST")(value)))]:(record.is_publicsale_tba)?[_vm._v(_vm._s(_vm.$t('TBA')))]:_vm._e()],2)}},{key:"amount",fn:function(record){return _c('div',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(record.value))+" ")])}},{key:"price",fn:function(record){return _c('div',{staticClass:"font-semibold"},[(_vm.projectDetail.is_publicsale_price_tba)?_c('div',[_vm._v(_vm._s(_vm.$t('TBA')))]):_c('div',[_vm._v(_vm._s(_vm._f("toCurrency")(record.value))+" "+_vm._s(_vm.symbol))])])}},{key:"whitelist",fn:function(record){return _c('div',{},[_c('a',{staticClass:"font-semibold"},[_vm._v(_vm._s(record.value))])])}}],null,false,51681672)})],1),_c('div',{staticClass:"w-full text-center py-8 bg-purple-bg"},[(_vm.connectedChainId === _vm.projectDetail.chain_id)?[(
          !_vm.projectDetail.is_publicsale_tba &&
          !_vm.projectDetail.is_presale_tba &&
          !_vm.projectDetail.is_publicsale_price_tba &&
          !_vm.projectDetail.is_presale_price_tba
        )?_c('button',{staticClass:"bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.handleButtonSaveClick}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_BASIC_INFO_SAVE_BTN'))+" ")]):_vm._e()]:_c('button',{staticClass:"bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl",on:{"click":function () { return _vm.$root.$emit('switch-metamask-chain', _vm.projectDetail.chain_id); }}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON_CONNECT_CHAIN', { chainName: _vm.CHAIN_INFO[_vm.projectDetail.chain_id].name }))+" ")])],2),(
      _vm.account &&
      (_vm.projectDetail.status < 2 || _vm.projectDetail.status_code === 'ERROR') &&
      _vm.userRole === 'admin'
    )?_c('div',{staticClass:"bg-purple-bg flex items-center justify-center pb-20 pt-10"},[_c('button',{staticClass:"flex items-center px-4 md:px-10 text-2xl py-2 rounded-lg bg-primary",attrs:{"disabled":_vm.loading},on:{"click":_vm.deleteAProject}},[_c('DeleteIcon',{staticClass:"text-white mr-2 w-8 h-8"}),_vm._v(" "+_vm._s(_vm.$t('PROJECT_BASIC_INFO_DELETE_BTN'))+" ")],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }